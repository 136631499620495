import imageCompression from 'browser-image-compression';
import piexif from "piexifjs";
import Semaphore from "./semaphore";

const semaphore = new Semaphore(4);

export const getImageDimensions = (url) => {
    return new Promise(((resolve, reject) => {
        let image = new Image();
        image.onload = function () {
            resolve({
                url: url,
                width: this.naturalWidth,
                height: this.naturalHeight
            });
            image.onload = null;
            image.src = null;
        };
        image.src = url;
    }));
};

export const getImageBlobData = (name, url) => {
    return new Promise((resolve, reject) => {
        fetch(url).then(x => x.blob()).then((x) => resolve({
            name: name,
            data: x
        })).catch(reject);
    })
}

const blobToBase64 = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
        let woExifData = piexif.remove(reader.result);
        resolve(woExifData);
    }
    reader.onerror = error => reject(error);
});

const compress = function (data) {
    return new Promise((resolve, reject) => {
        imageCompression(data, {
            useWebWorker: true,
            maxWidthOrHeight: 1100,
            maxSizeMB: 0.3,
            alwaysKeepResolution: true,
            maxIteration: 80,
        }).then(resolve).catch(reject);
    });
}


const resizeImage4 = function (url) {
    let localUrl = ((url.indexOf("relay") != -1 && url.indexOf("resize") == -1) ? `${url}&resizeHeight=920&quality=50` : url);
    return new Promise((resolve, reject) => {
        semaphore.acquire().then(() => {
            getImageBlobData('', localUrl)
                .then(imageData => {
                    let imageUrl = URL.createObjectURL(imageData.data);
                    getImageDimensions(imageUrl)
                        .then(imageSize => {
                            blobToBase64(imageData.data)
                                .then(base64Data => {
                                    resolve({
                                        url: url,
                                        width: imageSize.width,
                                        height: imageSize.height,
                                        data: base64Data
                                    })
                                }).catch(reject);
                        }).catch(reject);
                }).catch(reject)
                .finally(()=> semaphore.release());
        }).catch(()=>{
            semaphore.release();
        })
    })
}

export const getImageBlobDataWithDimension = (url, isCompress = false) => {
    return resizeImage4(url);
}

export const getImageHeight = (width, image) => {
    return (image.height * width) / image.width;
}

export const getImageWidth = (height, image) => {
    return (image.width * height) / image.height;
}
