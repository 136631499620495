import React, { Component } from "react";
import { Row, Col, Form, Button, Input, InputNumber, DatePicker } from "antd";
import * as dayjs from "dayjs";

class InvoiceBulkEdit extends Component {
  state = {};

  ValuesChange = (value) => {
    this.props.handleBulkEdit(value);
  };

  disabledDate = (current) => {
    const { selectedRows } = this.props;

    var start_date = new Date(
      Math.max(...selectedRows?.map((e) => new Date(e.start_date)))
    );
    var end_date = new Date(
      Math.min(...selectedRows?.map((e) => new Date(e.end_date)))
    );

    return (
      dayjs(current).format("YYYY-MM-DD") <
        dayjs(start_date).format("YYYY-MM-DD") ||
      dayjs(current).format("YYYY-MM-DD") > dayjs(end_date).format("YYYY-MM-DD")
    );
  };

  getMaxDays = () => {
    const { selectedRows } = this.props;
    return Math.min(...selectedRows?.map((e) => e?.no_of_days));
  };

  render() {
    const { active } = this.props;

    return (
      <Row
        style={{
          marginBottom: "16px",
          background: "#fafafa",
          border: "1px solid #f0f0f0",
        }}
      >
        <Col
          span="2"
          xs={24}
          xl={2}
          style={{
            padding: "16px",
            borderRight: "1px solid #f0f0f0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="invoice-bulkedit-title"
        >
          <b>Bult Edit</b>
        </Col>
        <Col
          span="21"
          xs={24}
          xl={21}
          style={{
            padding: "16px",
          }}
          className="invoice-bulkedit-form"
        >
          <Form
            name="bulk_edit"
            ref={this.formRef}
            onFinish={this.onFinish}
            className="bulk_edit"
            onValuesChange={this.ValuesChange}
            layout="inline"
          >
            {active === "Rent" && (
              <React.Fragment>
                <Form.Item name="start_date" label="Start Date">
                  <DatePicker
                    disabledDate={this.disabledDate}
                    inputReadOnly={true}
                  />
                </Form.Item>

                <Form.Item name="end_date" label="End Date">
                  <DatePicker
                    disabledDate={this.disabledDate}
                    inputReadOnly={true}
                  />
                </Form.Item>

                <Form.Item name="no_of_days" label="Number of Days">
                  <InputNumber
                    min={0}
                    max={this.getMaxDays()}
                    placeholder="Days"
                  />
                </Form.Item>

                <Form.Item
                  name="rent_notes"
                  label="Rental Notes"
                  style={{ width: "320px" }}
                >
                  <Input placeholder="Notes" />
                </Form.Item>
              </React.Fragment>
            )}

            {active === "Print" && (
              <React.Fragment>
                <Form.Item name="print_fl_rate" label="FL NL Price / SQFT">
                  <InputNumber min={0} placeholder="FL / NL" />
                </Form.Item>

                <Form.Item name="print_bl_rate" label="BL Price / SQFT">
                  <InputNumber min={0} placeholder="BL" />
                </Form.Item>

                <Form.Item
                  name="print_notes"
                  label="Notes"
                  style={{ width: "380px" }}
                >
                  <Input placeholder="Printing Notes" />
                </Form.Item>
              </React.Fragment>
            )}

            {active === "Mount" && (
              <React.Fragment>
                <Form.Item name="install_fl_rate" label="FL NL Price / SQFT">
                  <InputNumber min={0} placeholder="FL / NL" />
                </Form.Item>

                <Form.Item name="install_bl_rate" label="BL Price / SQFT">
                  <InputNumber min={0} placeholder="BL" />
                </Form.Item>

                <Form.Item
                  name="install_notes"
                  label="Notes"
                  style={{ width: "380px" }}
                >
                  <Input placeholder="Installation Notes" />
                </Form.Item>
              </React.Fragment>
            )}
          </Form>
        </Col>
      </Row>
    );
  }
}

export default InvoiceBulkEdit;
