//let apiUrl = "http://ec2-65-0-113-240.ap-south-1.compute.amazonaws.com/api/";
import AdminApi from "../iam/api/admin-api";

let apiUrl =
  process.env.REACT_APP_API_URL ?? "https://staging.ooh.systems/api/";

//Javascript Requester
export function ajaxRequester(reqURL, reqOBJ, callback, reqType) {
  AdminApi.getAuthToken().then((token) => {
    fetch(apiUrl + reqURL, {
      method: reqType ? reqType.toUpperCase() : "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cache: "no-cache",
      mode: "cors",
      body: reqOBJ ? JSON.stringify(reqOBJ) : null,
    })
      .then((res) => {
        return res.text();
      })
      .then((text) => {
        if (text === "" || !text) {
          callback({ success: true });
        } else {
          try {
            callback(JSON.parse(text));
          } catch (e) {
            callback(text);
          }
        }
      })
      .catch((e) => callback(e));
  });
}


export function getThumbUrl(url) {
  return getImageRelayUrl(url, 270, null, 70, true);
}

export function getImageRelayUrl(url, width, height, quality = 50, resize = false, downloadName = null) {
  let updateBaseUrl = apiUrl.replace("api/", "");
  return `${updateBaseUrl}relay?url=${encodeURIComponent(Buffer.from(url).toString('base64'))}` + ((height) ? `&height=${height}` : '') + ((width) ? `&width=${width}` : ``) + ((resize) ? `&resize=${resize}` : ``) + ((resize && quality) ? `&quality=${quality}` : ``) + ((downloadName) ? `&downloadName=${downloadName}` : ``);
}

export function getRelayUrl(url, name = null, resizeHeight=null, quality=null) {
  // Remove trailing slash from api url in baseApiUrl
  var baseApiUrl = apiUrl.replace(/\/$/, '');
  return `${baseApiUrl}/image/relay?from=${Buffer.from(url).toString('base64')}` + ((name) ? `&downloadName=${name}`:'') + ((resizeHeight) ? `&resizeHeight=${resizeHeight}`: '') + ((quality) ? `&quality=${quality}` : '');
}
