let apiUrl =
  process.env.REACT_APP_API_URL ?? "https://staging.ooh.systems/api/";

// Requester for Public Pages
export function ajaxRequesterPublic(reqURL, reqOBJ, callback, reqType) {
  fetch(apiUrl + reqURL, {
    method: reqType ? reqType.toUpperCase() : "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //   Authorization: `Bearer ${token}`,
    },
    cache: "no-cache",
    mode: "cors",
    body: reqOBJ ? JSON.stringify(reqOBJ) : null,
  })
    .then((res) => {
      return res.text();
    })
    .then((text) => {
      if (text === "" || !text) {
        callback({ success: true });
      } else {
        try {
          callback(JSON.parse(text));
        } catch (e) {
          callback(text);
        }
      }
    })
    .catch((e) => callback(e));
}
